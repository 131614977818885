import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/base/PrivateRoute'
import NavigationLayout from './components/layout/NavigationLayout'
import Header from './components/layout/Header'
import Sidebar from './components/layout/Sidebar'
import UsersPage from './components/page/manage/UsersPage'
import UserUpdateHistory from './components/page/manage/UsersPage/UserUpdateHistory'
import ForgetPasswordPage from "./components/page/LoginPage/ForgetPasswordPage";
import RolesPage from './components/page/manage/RolesPage'
import CaseTypePage from './components/page/master/CaseTypePage'
import UserFormPage from './components/page/manage/UserFormPage'
import PolicyTypePage from './components/page/master/PolicyTypePage'
import HospitalPage from './components/page/master/HospitalPage'
import GenerateCasePage from './components/page/case/GenerateCasePage'
import EditCasePage from './components/page/case/EditCasePage'
import CaseListPage from './components/page/case/CaseListPage'
import CaseCloseListPage from './components/page/case/CaseListPage/CaseCloseListPage'
import InitiateCasePage from './components/page/case/InitiateCasePage'
import CaseViewPageFactory from './components/page/case/CaseViewPage'
import LoginPage from './components/page/LoginPage'
import NotFound from './components/page/404'
import ClientPage from './components/page/master/ClientPage'
import ClientListingPage from './components/page/master/ClientListingPage'
import ProfilePage from './components/page/ProfilePage'
import SettingsPage from './components/page/SettingsPage'
import HomePage from './components/page/HomePage'
// import Notification from './components/layout/Header/Notification'
import NotificationPage from './components/layout/Header/NotificationPage'
import PerformanceEvaluationPage from './components/page/PerformanceEvaluationPage'
import UserWarningPage from './components/page/manage/UserWarningPage'
import AttendancePage from './components/page/attendance/AttendancePage'
import ReportPage from './components/page/attendance/ReportPage'
import HolidayPage from './components/page/attendance/HolidayPage'
import UsersSalaryPage from './components/page/SalaryPage/User'
import DocumentPendencyPage from './components/page/billing/DocumentPendencyPage'
import InvoiceSectionPage from './components/page/billing/InvoiceSectionPage'
import PaymentSectionPage from './components/page/billing/PaymentSectionPage'
import DocDispatchSectionPage from './components/page/billing/DocDispatchSectionPage'
import UserFormPageTemp from "./components/page/SalaryPage/UserFormPage/UserFormPage";
import BackupPage from './components/page/backup'
import States from './components/page/master/States'
import VisitPurpose from './components/page/master/VisitPurpose'
import CallPage from './components/page/case/CaseViewPage/actions/CallingPage'

import LeaveRequest from './components/page/master/LeaveRequest'
import CaseCategory from './components/page/master/CaseCategory'
import MrdNotifiction from './components/layout/Header/MrdNotifiction'
import InvoiceNotificationList from './components/layout/Header/InvoiceNotificationList'
import MrdChardes from './components/page/master/MrdCharges'
import CancelledByInvestigatorList from './components/page/master/CancelledByInvestigatorList'
import AddressChangeRequestList from './components/page/master/AddressChangeRequestLis'
import InvestigatorActivity from './components/page/master/InvestigatorActivity'
import InvestigatorActivityDetail from './components/page/master/InvestigatorActivity/InvestigatorActivityDetail'
import CaseInvestigatorsHistory from './components/page/case/CaseViewPage/History/CaseIvestigatorsHistory'
import ReportsPage from './components/page/master/ReportsPage'
import HospitalInfraInvestigator from './components/page/master/ReportsPage/Hospital/HospitalInfraInvestigator'
import HospitalInfraInvestigatorDetailForm from './components/page/master/ReportsPage/Hospital/HospitalInfraInvestigatorDetailForm'
import TreatingDoctors from './components/page/master/ReportsPage/TreatingDoctors'
import TreatngDoctorInvestigator from './components/page/master/ReportsPage/TreatingDoctors/TreatngDoctorInvestigator'
import TreatingDoctorDetailForm from './components/page/master/ReportsPage/TreatingDoctors/TreatingDoctorDetailForm'
import PathologistInvestigators from './components/page/master/ReportsPage/Pathologist/PathologistInvestigators'
import InsuredInvestigators from './components/page/master/ReportsPage/Insured/InsuredInvestigators'
import AttendentInvestigators from './components/page/master/ReportsPage/Attendent/AttendentInvestigators'
// import PathologistDetailForm from './components/page/master/ReportsPage/Pathologist/PathologistDetailForm'
import AttendantDetailsForm from './components/page/master/ReportsPage/Attendent/AttendantDetailsForm'
import InsuredDetailsForm from './components/page/master/ReportsPage/Insured/InsuredDetailsForm'
import LodgingExpensesPage from "./components/page/master/LodgingExpenses"
import CurrentUserLogOut from './components/page/manage/RolesPage/CurrentUserLogOut'

import PathologistDetailForm from './components/page/master/ReportsPage/Pathologist/PathologistDetailForm'
import RepudiationGround from './components/page/master/RepudiationGround'
const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path='/login' exact component={LoginPage} />
                <Route path='/forgot-password' exact component={ForgetPasswordPage} />
                <NavigationLayout header={<Header />} sidebar={<Sidebar />} >
                    <Switch>
                        <PrivateRoute path='/' exact component={HomePage} />
                        <PrivateRoute path='/performance-evaluation' exact component={PerformanceEvaluationPage} />
                        <PrivateRoute path='/profile' exact component={ProfilePage} />
                        <PrivateRoute path='/notification' exact component={NotificationPage} />
                        <PrivateRoute path='/MrdNotifiction' exact component={MrdNotifiction} />
                        <PrivateRoute path='/InvoiceNotificationList' exact component={InvoiceNotificationList} />


                        {/* Case Routes */}
                        <PrivateRoute path='/case/initiate' exact component={InitiateCasePage} />
                        <PrivateRoute path='/case/generate/:caseId' exact component={GenerateCasePage} />
                        <PrivateRoute path='/case/edit/:caseId' exact component={EditCasePage} />
                        <PrivateRoute path='/case/generate' exact component={GenerateCasePage} />
                        <PrivateRoute path='/case/list' exact component={CaseListPage} />
                        <PrivateRoute path='/case/closed/list' exact component={CaseCloseListPage} />
                        <PrivateRoute path='/case/view/:caseId' exact component={CaseViewPageFactory} />
                        <PrivateRoute path='/case/case-investigators-history/:Id' exact component={CaseInvestigatorsHistory} />

                        {/* Case Routes */}

                        {/* Attendance Routes */}
                        <PrivateRoute path='/attendance/mark' exact component={AttendancePage} />
                        <PrivateRoute path='/attendance/report' exact component={ReportPage} />
                        <PrivateRoute path='/attendance/holiday' exact component={HolidayPage} />
                        {/* Attendance Routes */}


                        {/* Manage Section Routes */}
                        <PrivateRoute path='/manage/users' exact component={UsersPage} />
                        <PrivateRoute path='/manage/users/add' exact component={UserFormPage} />
                        <PrivateRoute path='/manage/users/history/:userId' exact component={UserUpdateHistory} />
                        <PrivateRoute path='/manage/users/update/:userId' exact component={UserFormPage} />
                        <PrivateRoute path='/manage/roles' exact component={RolesPage} />

                        {/* <PrivateRoute path='/manage/roles' exact component={CurrentUserLogOut} /> */}

                        <PrivateRoute path='/manage/user-warning' exact component={UserWarningPage} />
                        {/* Manage Section Routes */}



                        {/* Master Section Routes */}
                        <PrivateRoute path='/master/case-category' exact component={CaseCategory} />

                        <PrivateRoute path='/master/casetype' exact component={CaseTypePage} />
                        <PrivateRoute path='/master/repudiation-ground' exact component={RepudiationGround} />

                        <PrivateRoute path='/master/client' exact component={ClientListingPage} />
                        <PrivateRoute path='/master/client/add' exact component={ClientPage} />
                        <PrivateRoute path='/master/client/update/:clientId' exact component={ClientPage} />
                        <PrivateRoute path='/master/policytype' exact component={PolicyTypePage} />
                        <PrivateRoute path='/master/hospital' exact component={HospitalPage} />

                        <PrivateRoute path='/master/states' exact component={States} />
                        <PrivateRoute path='/master/leave-request' exact component={LeaveRequest} />
                        <PrivateRoute path="/master/mrd-charges" exact component={MrdChardes} />
                        <PrivateRoute path="/master/lodging-expenses" exact component={LodgingExpensesPage} />


                        <PrivateRoute path="/master/cancelled-by-investigator" exact component={CancelledByInvestigatorList} />
                        <PrivateRoute path="/master/address-change-request" exact component={AddressChangeRequestList} />
                        <PrivateRoute path="/master/investigator-activity" exact component={InvestigatorActivity} />
                        <PrivateRoute path='/master/investigator-activity-detail/:Id' exact component={InvestigatorActivityDetail} />

                        <PrivateRoute path='/master/reports' exact component={ReportsPage} />
                        <PrivateRoute path='/master/reports/hospital-infra-investigator/:Id' exact component={HospitalInfraInvestigator} />
                        <PrivateRoute path='/master/reports/hospital-infra-investigator/:Id/hospital-infra-investigator-detail-form/:Id' exact component={HospitalInfraInvestigatorDetailForm} />

                        <PrivateRoute path='/master/reports/treating-doctor' exact component={TreatingDoctors} />
                        <PrivateRoute path='/master/reports/treating-doctor/treatng-doctor-investigator/:Id' exact component={TreatngDoctorInvestigator} />
                        <PrivateRoute path='/master/reports/treating-doctor/treatng-doctor-investigator/:Id/treating-doctor-detail/:Id' exact component={TreatingDoctorDetailForm} />

                        <PrivateRoute path='/master/reports/pathologist-investigators/:Id' exact component={PathologistInvestigators} />
                        <PrivateRoute path='/master/reports/pathologist-investigators/:Id/pathologist-detail/:Id' exact component={PathologistDetailForm} />

                        <PrivateRoute path='/master/reports/insured-investigator/:Id' exact component={InsuredInvestigators} />
                        <PrivateRoute path='/master/reports/insured-details/:Name/:dob' exact component={InsuredDetailsForm} />

                        <PrivateRoute path='/master/reports/attendent-investigators/:Id' exact component={AttendentInvestigators} />
                        <PrivateRoute path='/master/reports/attendant-details/:Name/:dob' exact component={AttendantDetailsForm} />

                        <PrivateRoute path='/master/visit-purpose' exact component={VisitPurpose} />
                        {/* Master Section Routes */}


                        {/*Salary section routes*/}
                        <PrivateRoute path='/salary' exact component={UsersSalaryPage} />
                        <PrivateRoute path='/salary/:userId/:month/:year' exact component={UserFormPageTemp} />
                        {/*Salary section routes*/}

                        {/*Billing section routes*/}
                        <PrivateRoute path='/document-pendency' exact component={DocumentPendencyPage} />
                        <PrivateRoute path='/invoice' exact component={InvoiceSectionPage} />
                        <PrivateRoute path='/payment' exact component={PaymentSectionPage} />
                        <PrivateRoute path='/doc-dispatch' exact component={DocDispatchSectionPage} />
                        {/*Billing section routes*/}

                        <PrivateRoute path='/settings' exact component={SettingsPage} />

                        <PrivateRoute path='/backup' exact component={BackupPage} />
                        <PrivateRoute  path='/calling' exact component={CallPage}></PrivateRoute>
                        <PrivateRoute path='*' component={NotFound} />
                    </Switch>
                </NavigationLayout>
            </Switch>
        </Router>
    )
}

export default Routes
