import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'
import RequirePermission from '../../base/RequirePermission'
import './../../../common/permissions'
import permissions from './../../../common/permissions'

const Sidebar = (): JSX.Element => {
  return (
    <div className="sidebar-container" style={{ height: '100%' }}>
      <ul>
        <li className="sidebar-item-header">
          <Link to="/">Home</Link>
        </li>
        {/*<li className='sidebar-item'>*/}
        {/*    <Link to='/'>*/}
        {/*        <div>Home</div>*/}
        {/*    </Link>*/}
        {/*</li>*/}
        {/*<li className='sidebar-item'>*/}
        {/*    <Link to='/performance-evaluation'>*/}
        {/*        <div>Performance Evaluation</div>*/}
        {/*    </Link>*/}
        {/*</li>*/}
      </ul>

      {/* Case Section */}
      <RequirePermission permission={[permissions.Case.initiate, permissions.Case.generate, permissions.Evaluation.find]} any>
        <div className="sidebar-item-divider" />
        <ul>
          <li className="sidebar-item-header">Case</li>
          <RequirePermission permission={permissions.Case.initiate}>
            <li className="sidebar-item">
              <Link to="/case/initiate">
                <div>Initiate</div>
              </Link>
            </li>
          </RequirePermission>

          <RequirePermission permission={permissions.Case.generate}>
            <li className="sidebar-item">
              <Link to="/case/generate">
                <div>Generate</div>
              </Link>
            </li>
          </RequirePermission>

          <RequirePermission permission={permissions.Case.generate}>
            <li className="sidebar-item">
              <Link to="/case/closed/list">
                <div>Closed Cases</div>
              </Link>
            </li>
          </RequirePermission>

          <RequirePermission permission={permissions.Evaluation.find}>
            <li className="sidebar-item">
              <Link to="/performance-evaluation">
                <div>Performance Evaluation</div>
              </Link>
            </li>
          </RequirePermission>

          <li className="sidebar-item">
            <Link to="/master/investigator-activity">
              <div>Investigator Activity</div>
            </Link>
          </li>
        </ul>
      </RequirePermission>
      {/* Case Section */}

      {/* Attendance Section */}
      <div className="sidebar-item-divider" />
      <ul>
        <li className="sidebar-item-header">Attendance</li>
        {/*<li className='sidebar-item'>*/}
        {/*    <Link to='/attendance/mark'>*/}
        {/*        <div>Mark</div>*/}
        {/*    </Link>*/}
        {/*</li>*/}
        <li className="sidebar-item">
          <Link to="/attendance/report">
            <div>View/Upload</div>
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/attendance/holiday">
            <div>Holiday</div>
          </Link>
        </li>
      </ul>
      {/* Attendance Section */}

      {/* Manage Section */}
      <RequirePermission permission={permissions.Manage.CRUD}>
        <div className="sidebar-item-divider" />
        <ul>
          <li className="sidebar-item-header">Manage</li>
          <li className="sidebar-item">
            <Link to="/manage/users">
              <div>Users</div>
            </Link>
          </li>

          <li className="sidebar-item">
            <Link to="/manage/user-warning">
              <div>User Warning</div>
            </Link>
          </li>

          <li className="sidebar-item">
            <Link to="/manage/roles">
              <div>Roles & Permissions</div>
            </Link>
          </li>
         
        </ul>
      </RequirePermission>
      {/* Manage Section */}

      <RequirePermission permission={permissions.Master.CRUD}>
        <div className="sidebar-item-divider" />
        <ul>
          <li className="sidebar-item-header">Master Record</li>
          <li className="sidebar-item">
            <Link to="/master/case-category">
              <div>Case Category</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/master/casetype">
              <div>Case Type</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/master/repudiation-ground">
              <div>Repudiation Ground</div>
            </Link>
          </li>

          <li className="sidebar-item">
            <Link to="/master/client">
              <div>Client</div>
            </Link>
          </li>

          <li className="sidebar-item">
            <Link to="/master/policytype">
              <div>Policy Type</div>
            </Link>
          </li>

          <li className="sidebar-item">
            <Link to="/master/hospital">
              <div>Hospital</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/master/states">
              <div>States</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/master/leave-request">
              <div>Leave Request</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/master/mrd-charges">
              <div>MRD Request</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/master/lodging-expenses">
              <div>Lodging Expenses</div>
            </Link>
          </li>


          <li className="sidebar-item">
            <Link to="/master/cancelled-by-investigator">
              <div>Cancelled By Investigator</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/master/address-change-request">
              <div>Address Change Request</div>
            </Link>
          </li>
          {/* <li className="sidebar-item">
            <Link to="/master/investigator-activity">
              <div>Investigator Activity</div>
            </Link>
          </li> */}
          <li className="sidebar-item">
            <Link to="/master/reports">
              <div>Reports</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/master/visit-purpose">
              <div>Visit Purpose</div>
            </Link>
          </li>
        </ul>
      </RequirePermission>

      <RequirePermission permission={permissions.Billing.update}>
        <div className="sidebar-item-divider" />
        <ul>
          <li className="sidebar-item-header">Billing</li>
          <li className="sidebar-item">
            <Link to="/document-pendency">
              <div>Document Pendency</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/invoice">
              <div>Invoice</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/payment">
              <div>Payment</div>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/doc-dispatch">
              <div>Document Dispatch</div>
            </Link>
          </li>
        </ul>
      </RequirePermission>

      <RequirePermission permission={permissions.Setting.update}>
        <div className="sidebar-item-divider" />
        <ul>
          {/*<li className='sidebar-item-header'>Settings</li>*/}
          <li className="sidebar-item-header">
            <Link to="/salary">
              {/*<Icon name='setting'/>*/}
              Salary
            </Link>
          </li>
        </ul>
      </RequirePermission>

      <RequirePermission permission={permissions.Setting.update}>
        <div className="sidebar-item-divider" />
        <ul>
          {/*<li className='sidebar-item-header'>Settings</li>*/}
          <li className="sidebar-item-header">
            <Link to="/settings">
              {/*<Icon name='setting'/>*/}
              Settings
            </Link>
          </li>
        </ul>
      </RequirePermission>

      <RequirePermission permission={permissions.Backup}>
        <div className="sidebar-item-divider" />
        <ul>
          <li className="sidebar-item-header">
            <Link to="/backup">Backup</Link>
          </li>
        </ul>
      </RequirePermission>
    </div>
  )
}

export default Sidebar
