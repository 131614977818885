import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface NotificationPopupProps {
  invoiceData: {
    title: string;
    type: string;
    type_id: string;
    history_id?: string;
    length: number;
    message: string;
    metadata: {
      document_expense: number;
      document_expense_receipt: Array<any>;
      approved_expense: number;
      approved_expense_receipt: Array<any>;
      extra_expense: number;
      claim_number: number;
      case_id: number;
      client_name:string;
    };
  };
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({ invoiceData }) => {
  const [showPopup, setShowPopup] = useState(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // If more than 1 invoice data exists, redirect to listing page
    if (invoiceData.length > 1) {
      setShowPopup(false);
      history.push({
        pathname: '/InvoiceNotificationList',
        state: { notificationData: invoiceData },
      });
    } else {
      // If only one invoice data exists, show the popup
      setShowPopup(true);
    }
  }, [invoiceData, history]);

  // Determine if the user is already on the InvoiceNotificationList page
  const isOnViewPage = location.pathname === '/InvoiceNotificationList';

  const handleOpenInvoice = () => {
    // If the popup is showing and user clicks "View", navigate to the listing page
    history.push({
      pathname: '/InvoiceNotificationList',
      state: { notificationData: invoiceData },
    });
    setShowPopup(false); // Close the popup after clicking
  };

  useEffect(() => {
    // If the user navigates away from the view page, show the popup again
    if (!isOnViewPage) {
      setShowPopup(true);
    }
  }, [location, isOnViewPage]);

  // If the popup is hidden or if we're on the view page, don't render anything
  if (!showPopup || isOnViewPage) return null;

  return (
    <div className={`notification-popup ${showPopup ? 'show' : ''}`}>
      <div className="overlay"></div>
      <div className="dialog-container">
        <div className="dialog-content">
          <div className="notification-details">
            <h1 style={{ color: '#FFFFFF' }}>{invoiceData.title}</h1>
            <h2 style={{ color: '#FF0000' }}>Client Name:{invoiceData.metadata.client_name}</h2>
            <h2 style={{ color: '#ffcc00' }}>Claim Number: {invoiceData.metadata.claim_number}</h2>
            <h3 style={{ color: '#FFFFFF' }}>{invoiceData.message}</h3>
          </div>
          <div className="button-container">
            <button className="dialog-button primary" onClick={handleOpenInvoice}>
              View
            </button>
          </div>
        </div>    
      </div>
    </div>
  );
};

export default NotificationPopup;
