import DomainModel from './DomainModel'
import LocalStorage from '../lib/LocalStorage';
import config from '../common/repository/config';
export default class Role extends DomainModel {
  private permissionMap: Map<string, boolean>
  constructor(private id: string, private title: string, private level: number, private permissions: string[], private backendPermissions: string[],
    private stakeholder: string[] = [], private stakeholder_role: string[]
  ) {
    super()
    this.permissionMap = new Map(this.permissions.map((it) => [it, true]))
  }

  getId = () => this.id
  getTitle = () => this.title
  getLevel = () => this.level
  getPermissions = () => this.permissions
  getBackendPermissions = () => this.backendPermissions
  // getStakeHolder = async () => {
  //   await this.fetchStakeholderRoles(); // Fetch stakeholder roles first
  //   return this.stakeholder; // Return stakeholder data after fetching
  // };  

  getSelectedStakeholderRole = () => this.stakeholder_role;


  hasPermission = (permission: string | string[], any?: boolean) => {
    if (Array.isArray(permission)) return this.hasPermissionMultiple(permission, any)
    return this.hasPermissionSingle(permission)
  }

  private hasPermissionSingle = (permission: string) => this.permissionMap.has(permission)

  private hasPermissionMultiple = (permissions: string[], any?: boolean) => {
    if (any) return this.hasAny(permissions)
    return this.hasAll(permissions)
  }

  private hasAll = (permissions: string[]) => {
    let hasAll = true
    for (let i = 0; i < permissions.length; i++) {
      if (!this.hasPermissionSingle(permissions[i])) {
        hasAll = false
        break
      }
    }
    return hasAll
  }

  private hasAny = (permissions: string[]) => {
    let hasAny = false
    for (let i = 0; i < permissions.length; i++) {
      if (this.hasPermissionSingle(permissions[i])) {
        hasAny = true
        break
      }
    }
    return hasAny
  }
}
