import React, { useState, useEffect, ReactNode, useRef } from "react";
import ReactDOM from "react-dom";
import { Segment, Header, Button, Modal, Table, Icon, TextArea, Dropdown, DropdownItemProps, InputOnChangeData } from "semantic-ui-react";
import AllocatedInvestigator from "../../../../../../models/AllocatedInvestigator";
import User from "../../../../../../models/User";
import FileLabel from "../../../../../common/FileLabel";
import UploadEvidenceForm from "./UploadEvidenceForm";
import FinishInvestigationForm from "./FinishInvestigationForm";
import URLResource from "../../../../../../models/URLResource";
import Remove from "../../../../../common/Remove";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";
import RequirePermission from "../../../../../base/RequirePermission";
import permissions from "../../../../../../common/permissions";
import FilesPopup from "../../FilesPopup";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
import APIResponseModel from "../../../../../../common/repository/APIResponseModel";
import { access } from "fs";
import LocalStorage from "../../../../../../lib/LocalStorage";
import config from "../../../../../../common/repository/config";
import Input from "../../../../../elements/Input";
import { RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../../../redux";
import { useSelector } from "react-redux";
import constants from "../../../../../../common/constants";
interface InvestigatorCardProps {
  model: AllocatedInvestigator;
  case_id: string;
  updateModel?(model: AllocatedInvestigator): void;
  removeModel?(model: AllocatedInvestigator): void;
  enableReinvestigation?(model: AllocatedInvestigator): void;
}
// interface ExpenseDetail {
//   amount: string;
//   receipt: string | null;
//   payment_status: string;
//   amount_status: string;
//   receipt_status: string;
//   history_id: string;
// }
interface MatchProps {
  caseId: string;
}
// interface RejectionDetail {
//   caseId:string,
//   investigatorName:string,
//   investigatorId:string,
//   amount:string,
//   typeId:string,
//   receiptStatus:string
// }
interface Props extends RouteComponentProps<MatchProps> { }
interface CaseItem {
  case_id: string;
  expense_details: ExpenseDetail[];
}
interface ExpenseDetail {
  amount: string; // Updated to match the response
  amount_rejection_reason: string | null;
  amount_status: string;
  history_id: string;
  payment_status: string;
  receipt: any[]; // Adjust based on the actual type of receipt
  receipt_rejection_reason: string | null;
  receipt_status: string;
  _id: string;
}

interface Item {
  [x: string]: any;
  amount: string; 
  _id: string;
  case_id: string;
  hospital: {
    name: string;
  };
  hospital_id: string;
  investigator: {
    name: string;
  };
  investigator_id: string;
  expense_details: ExpenseDetail[];
}

interface GroupedData {
  [investigator_id: string]: Item[];
}
function InvestigatorCard(props: InvestigatorCardProps) {
  
  const authState = useSelector((state: AppState) => state.auth);
  const portalRoot = document.getElementsByTagName("body")[0];
  const caseRepo = new CaseRepository();
  const { model, updateModel } = props;
  const investigator = model.getInvestigator();
  const user = authState.user;
  let [evidence, setEvidence] = useState(model.getEvidence);
  let [removedEvidence, setRemoveEvidence] = useState(model.getRemovedEvidence);
  const [downloading, setDownloading] = useState(false);
  const [data, setData] = useState<GroupedData>({});

  const [showRejectReasonInput, setShowRejectReasonInput] = useState(false);
  const [rejectReason, setRejectReason] = useState("")
  const [rejectionReasons, setRejectionReasons] = useState<DropdownItemProps[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  

  


  const updateRemoveEvidence = (e: URLResource[], index: any) => {
    setRemoveEvidence((prev: any) => {
      const updatedArray = [...prev]; // Copy the previous state
      updatedArray[index] = { // Update the item at the specified index
        ...updatedArray[index],
        document: e
      };
      return updatedArray;
    });
  };
  const groupByInvestigator = (data:any) => {
    return data.reduce((groups:any, item:any) => {
      const { investigator_id } = item;
      if (!groups[investigator_id]) {
        groups[investigator_id] = [];
      }
      groups[investigator_id].push(item);
      return groups;
    }, {});
  };
  const handleBulkDownload = async (allocationId: string) => {
    setDownloading(true); // Set downloading state to true
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN); // Retrieve token from localStorage
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }
      const response = await fetch(`${config.apiBaseURL}/case/allocate/bulk-download/${allocationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          evidenceUrls: evidence.map((item) => item.getURL()), // Adjust based on your data structure
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to download: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const disposition = response.headers.get('Content-Disposition');
      console.log("Disposition Header", disposition);
      let fileName = `Evidence ${allocationId}`; //default fileName
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const parts = disposition.split(";");
        for (let part of parts) {
          part = part.trim();
          if (part.startsWith('filename=')) {
            fileName = part.substring(9).replace(/['"]/g, '');
          }
          // const matches = fileNameRegex.exec(disposition);
          // if (matches != null && matches[1]) {
          //   fileName = matches[1].replace(/['"]/g, '');
          // }
        }
      }
      console.log('Content-Type header:', fileName);
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      console.log('ZIP file downloaded successfully');
    } catch (error) {
      console.error('Error in handleBulkDownload:', error);
    } finally {
      setDownloading(false);
    }
  };

  


  const updateEvidence = (e: URLResource[]) => {
    setEvidence(e);
  };
  // const handleAmountChange = (newValue: string, rowIndex: number, index: number) => {
  //   setData(prevData => {
  //     const newData = [...prevData];
  //     newData[rowIndex].expense_details[index].amount = newValue;
  //     return newData;
  //   });
  // };
  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    investigatorId: string,
    detailId: string
  ) => {
    const newValue = e.target.value;
    console.log('handleAmountChange:', { newValue, investigatorId, detailId });
  
    setData(prevData => ({
      ...prevData,
      [investigatorId]: prevData[investigatorId].map(item => ({
        ...item,
        expense_details: item.expense_details.map(detail =>
          detail._id === detailId ? { ...detail, amount: newValue } : detail
        )
      }))
    }));
  };
  
  useEffect(() => {
    const fetchCaseData = async () => {
      try {
  
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        // Retrieve token from localStorage
        if (!token) {
          console.error('Authentication token is missing.');
          return;
        }
        const response = await fetch(`${config.apiBaseURL}/case/listMrd`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': `${token}`
          },
          body: JSON.stringify({
            caseId: props.case_id
          })
        });
  
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }
  
        const responseData = await response.json();
        console.log(responseData, "{_dfff_");
        const groupedData = groupByInvestigator(responseData.data.items);
        console.log(groupedData);
        setData(groupedData);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchCaseData();
  },[]);

  // Check if model is defined and if it has an investigator
  const investigatorDetail = model ? model.getInvestigator() : null;
  
  // Ensure investigatorDetail is a User instance before calling getId
  const investigatorId = investigatorDetail instanceof User ? investigatorDetail.getId() : '';

  // Filter the data based on the investigator ID from the model
  const filteredData = investigatorId ? data[investigatorId] || [] : [];
  console.log("filteredData",filteredData)
  useEffect(() => {
    const fetchRejectionReasons = async () => {
      try {
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        // Retrieve token from localStorage
        if (!token) {
          console.error('Authentication token is missing.');
          return;
        }
        const response = await fetch(`${config.apiBaseURL}/case/listRejectionReason`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': `${token}`
          },
        });

        if (!response.ok) throw new Error('Failed to fetch rejection reasons');
        const data = await response.json();

        if (data.success && data.data.items) {
          const reasons: DropdownItemProps[] = Object.entries(data.data.items).map(([key, value]) => ({
            key,
            text: value as string,
            value: key
          }));
          setRejectionReasons(reasons);
        }
      } catch (error) {
        console.error('Error fetching rejection reasons:', error);
      }
    };

    fetchRejectionReasons();
  }, []);


  const checkMRDReceiptStatus = async (caseId: string) => {
    setIsApiPending(true);
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }

      const response = await fetch(`${config.apiBaseURL}/case/checkMrd`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ caseId }),
      });

      if (!response.ok) {
        throw new Error(`Failed to check MRD receipt status: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log('MRD receipt status:', responseData);

      if (responseData.receiptStatus) {
        setReceiptStatus(true);
      } else {
        setReceiptStatus(false);
        toast.error('Please check MRD receipt status.');
      }
    } catch (error) {
      console.error('Error checking MRD receipt status:', error);
      toast.error('Failed to check MRD receipt status.');
    } finally {
      setIsApiPending(false);
    }
  };


  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [uploadEvidenceModalOpen, setUploadEvidenceModalOpen] = useState(false);
  const [isApiPending, setIsApiPending] = useState(false);
  const [receiptStatus, setReceiptStatus] = useState<boolean>(false); // boolean instead of string

  const [finishInvestigationModalOpen, setFinishInvestigationModalOpen] = useState(false);
  
  const isCA = user?.getRole().getTitle() === constants.roleTitle.ca || constants.roleTitle.oh;
  // const [selectedDetail, setSelectedDetail] = useState({
  //   isVisible: false,
  //   case_Id: '',
  //   history_id:'',
  //   investigator_Id: '',
  //   detail_Id: '',
  //   amount: '',
  //   receipt_Status: '',
  //   investigator_Name: '',
  // });
  const [selectedDetail, setSelectedDetail] = useState<{ 
    isVisible: boolean;
    detail_Id: string | null;
    investigator_Name?: string;
    amount?: string;
    history_id?: string;
    case_Id?: string;
    investigator_Id?: string;
    receipt_Status?: string;
  }>({
    isVisible: false,
    detail_Id: null,
  });

  const [openIndex, setOpenIndex] = useState(-1);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    effectRemoveInvestigator();
  }, [isRemoving]);




  let uploadEvidence: React.ReactNode | null = null;
  let finishInvestigation: React.ReactNode | null = null;
  let reopenInvestigation: React.ReactNode | null = null;
  if (model.getActive()) {
    uploadEvidence = (
      <Modal
        open={uploadEvidenceModalOpen}
        onClose={() => setUploadEvidenceModalOpen(false)}
        size="mini"
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        trigger={
          <Button
            primary
            disabled={!model.getActive()}
            onClick={() => setUploadEvidenceModalOpen(true)}
          >
            Upload Evidence
          </Button>
        }
      >
        <Modal.Header>Upload Evidence</Modal.Header>
        <Modal.Content>
          <UploadEvidenceForm
            setEvidence={setEvidence}
            model={model}
            onSuccess={(model) => {
              setUploadEvidenceModalOpen(false);
              if (updateModel) updateModel(model);
            }}
          />
        </Modal.Content>
      </Modal>
    );
    finishInvestigation = (
      <Modal
        open={finishInvestigationModalOpen}
        onClose={() => setFinishInvestigationModalOpen(false)}
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        trigger={
          <Button
          primary
          onClick={async () => {
            // Call checkMrdList and wait for the response
            const isApproved = await checkMrdList(props.case_id,investigatorId);

            // Check the receipt status and open the modal if approved
            if (isApproved.success === true ) {
              setFinishInvestigationModalOpen(true);
            } else if (isApproved.message !== "No mrd is found") {
              toast.error(isApproved.message);
            }
          }}
        >
          Finish Investigation
        </Button>
        }
      >
        <Modal.Content>
          <FinishInvestigationForm
            model={props.model}
            onSuccess={(model) => {
              setFinishInvestigationModalOpen(false);
              if (updateModel) updateModel(model);
            }}
          />
        </Modal.Content>
      </Modal>
    );
    
    
  } else {
    reopenInvestigation = (
      <Button
        primary
        onClick={() =>
          props.enableReinvestigation
            ? props.enableReinvestigation(model)
            : null
        }
      >
        Re-Open Investigation
      </Button>
    );
  }
  const checkMrdList = async (caseId: string, investigatorId: string) => {
    try {
      // const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      // if (!token) {
      //   console.error('Authentication token is missing.');
      //   return false; // Return false if the token is missing
      // }
  
      const response = await fetch(`${config.apiBaseURL}/case/checkMrd`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          caseId: caseId,
          investigatorId: investigatorId,
        }),
      });

     if (response.status === 422) {
      setFinishInvestigationModalOpen(true);
     }
  
      const responseData = await response.json();
  
      // Assuming responseData has an `isApproved` field that is a boolean
      return responseData; // Return the boolean value here
    } catch (error) {
      return false; // Return false in case of an error
    }
  };
  
  
  
  const approveMRDReceipt = async (historyId:any,caseId: string, investigatorId: string, typeId: string, amount: string, receipt_status: string, rejectReason?: string) => {
    try {
      console.log(historyId,"History")
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }

      const response = await fetch(`${config.apiBaseURL}/case/mrdReceiptStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          historyId:historyId,
          caseId: caseId,
          investigatorId: investigatorId,
          typeId: typeId,
          amount: amount,
          receiptStatus: receipt_status,
          receiptRejectionReason: rejectReason || '',
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update MRD receipt status: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log(responseData, "_____________===");
      if (rejectReason) {
        toast.success("MRD receipt rejected successfully");
        setShowRejectReasonInput(false);
        setRejectReason("");
      } else {
        toast.success("MRD receipt approved successfully");
      }

      console.log('MRD receipt status updated successfully:', responseData);
    } catch (error) {
      console.error('Error updating MRD receipt status:', error);
      toast.error("Failed to update MRD receipt status");
    }
  };

  const handleRejectConfirm = async (historyId:string,caseId: string, investigatorId: string, typeId: string, amount: string, receipt_status: string, reject_reason: string, detail: any) => {
    try {
      await approveMRDReceipt(historyId,caseId, investigatorId, typeId, amount, receipt_status, reject_reason);
      if (detail.receipt === 'Required' && !detail.receipt) {
        toast.error("Receipt is required but not provided.");
        return;
      }
      // toast.success("MRD receipt rejected successfully");
      setSelectedDetail({ isVisible: false, detail_Id: null });
      // setSelectedDetail({ ...selectedDetail, isVisible: false });
      setRejectReason(""); // Clear reject reason after successful rejection

    } catch (error) {
      console.error('Error rejecting MRD receipt:', error);
      toast.error("Failed to reject MRD receipt");
    }
  };



  const handleRejection = ( historyId:any,caseId: string, investigatorId: string, typeId: string, amount: string, receipt_status: string, investigator_name: string) => {
    setSelectedDetail({
      isVisible: true,
      case_Id: caseId,
      history_id:historyId,
      investigator_Id: investigatorId,
      detail_Id: typeId,
      amount: amount,
      receipt_Status: receipt_status,
      investigator_Name: investigator_name,
    });
  }
  
  return (
    <>
      <Segment>
        <div>
          {/* section */}
          <div>
            <Header as="h4">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {investigator.getName()}
                  {renderSubHeader(model)}
                </div>

                {/* section */}
                <div>
                  <Button primary onClick={() => handleBulkDownload(props.model.getId())} disabled={downloading || evidence.length == 0}>
                    {downloading ? (
                      <Icon loading name='spinner' />
                    ) : (
                      <Icon name='download' />
                    )}
                    Download Evidence
                  </Button>
                  {/* upload evidence form */}
                  {uploadEvidence}
                  {/* upload evidence form */}

                  {/* finish investigation form */}
                  {finishInvestigation}
                  {/* finish investigation form */}


                  {/*Reopen investigation */}
                  {reopenInvestigation}
                  {/*Reopen investigation */}

                  {/* remove investigator */}
                  <RequirePermission
                    permission={permissions.Case.Investigator.remove}
                  >
                    <Remove.Modal
                      open={isOpenRemoveModal}
                      onClose={() => setIsOpenRemoveModal(false)}
                      trigger={
                        <Button
                          negative
                          onClick={() => setIsOpenRemoveModal(true)}
                        >
                          Remove Investigator
                          {/* upload evidence form */}
                        </Button>
                      }
                    >
                      <Remove
                        loading={isRemoving}
                        onCancel={() => setIsOpenRemoveModal(false)}
                        onRemove={() => setIsRemoving(true)}
                      />
                    </Remove.Modal>
                    {/* <Button
                primary
                  negative
                  onClick={() => handleBulkDownload(model.getId())} 
                >
                   Download Evidence
                </Button> */}
                  </RequirePermission>
                  {/* remove investigator */}
                </div>
                {/* section */}
              </div>
            </Header>
            {/*{renderEvidenceList(evidence)}*/}

            <FileLabel.Group horizontal>
              {evidence.map((it) => {
                let removable = true;
                // if (evidence.length === 1)
                //     removable = false
                return (
                  <FileLabel
                    title={it.getFullName().slice(0, 30)}
                    link={it.getURL()}
                    removable={removable}
                    remove={async () => {
                      const result = await caseRepo.removeInvestigationDocument({
                        allocation_id: model.getId(),
                        document_url: it.getURL(),
                      });
                      if (result instanceof Result.Success) {
                        const c = result.data;
                        let updatedData: URLResource[] = [];
                        c.items.forEach((it) => {
                          if (model.getId() == it.getId()) {
                            updatedData = it.getEvidence();
                          }
                        });
                        updateEvidence(updatedData);
                        toast.success("Document removed successfully");
                        return true;
                      } else {
                        const message = result.message || "Something went wrong";
                        toast.error(message);
                        return false;
                      }
                    }}
                  />
                );
              })}
            </FileLabel.Group>
            <div >
              <br />
              <h4>Removed Evidence :</h4>&nbsp;&nbsp;&nbsp;
              <div>
                {console.log(removedEvidence, "removedEvidence")}
                {removedEvidence?.map((item, index) => (
                  <span key={index}>
                    {item.document.length === 0 ? `No ${item.type}` : `View ${item.type}`}
                    <FileLabel.Group horizontal>
                      {item?.document?.map((it, Fileindex) => {
                        let removable = true;
                        // if (evidence.length === 1)
                        //     removable = false
                        return (
                          <div key={Fileindex} style={{ borderRadius: "50px", padding: "2px 0px 0px 2px", marginLeft: "3px" }}>
                            <FileLabel
                              // color='red'
                              title={it.getFullName().slice(0, 30)}
                              link={it.getURL()}
                              removable={removable}
                              remove={async () => {
                                const result = await caseRepo.removeInvestigationDocument({
                                  allocation_id: model.getId(),
                                  document_url: it.getURL(),
                                });
                                if (result instanceof Result.Success) {
                                  const c = result.data;
                                  let updatedData: URLResource[] = [];
                                  c.items.forEach((it) => {
                                    if (model.getId() == it.getId()) {
                                      setRemoveEvidence(it.getRemovedEvidence())
                                      updatedData = it.getEvidence();
                                    }
                                  });
                                  // console.log(updatedData, "updatedData")
                                  // updateRemoveEvidence(updatedData, index);
                                  toast.success("Document removed successfully");
                                  return true;
                                } else {
                                  const message = result.message || "Something went wrong";
                                  toast.error(message);
                                  return false;
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </FileLabel.Group>
                  </span>
                ))}
              </div>
            </div>
          </div>
          {/* section */}
        </div>
      </Segment>
      <Segment>
      {filteredData.length > 0 ? (
        
        <>
          <Header as='h3'>Investigator: {filteredData[0].investigator.name}</Header>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
              <Table.HeaderCell>Hospital Name</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Receipt</Table.HeaderCell>
              <Table.HeaderCell>Payment Status</Table.HeaderCell>
              <Table.HeaderCell>Amount Status</Table.HeaderCell>
              <Table.HeaderCell>Receipt Status</Table.HeaderCell>
              {isCA && (
                <Table.HeaderCell>Actions</Table.HeaderCell>
              )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {filteredData.map(item =>
                item.expense_details.map(detail => (
                  <Table.Row key={detail._id}>
                  <Table.Cell>{item.hospital.name}</Table.Cell>
                  <Table.Cell>
                      <Input
                        value={detail.amount}
                        // onChange={(e) => handleAmountChange(e.target.value, rowIndex, index)}
                        onChange={(e) => handleAmountChange(e, investigatorId, detail._id)}
                        type="number"
                        disabled={detail.receipt_status === 'Approved'}
                      />
                    </Table.Cell>
                  <Table.Cell>
                      <div style={{ display: 'flex' }}>
                        {renderReceiptList(detail.receipt)}
                      </div>
                    </Table.Cell>
                  {/* <Table.Cell>{detail.receipt.length > 0 ? detail.receipt:"Receipt is not uploaded yet"}</Table.Cell> */}
                  <Table.Cell>{detail.payment_status}</Table.Cell>
                  <Table.Cell>{detail.amount_status}</Table.Cell>
                  <Table.Cell>{detail.receipt_status}</Table.Cell>
                  <Table.Cell>
                    {isCA && (
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                          content={`ID: ${detail._id}`}
                          primary
                          style={{ backgroundColor: 'green', color: 'white' }}
                          onClick={() => approveMRDReceipt(detail.history_id, item.case_id, item.investigator_id, detail._id, detail.amount, "Approved")}
                          disabled={ detail.receipt_status === 'Approved'  || detail.receipt.length === 0 || (detail.receipt_status ==="Rejected" && detail.receipt.length === 1) }
                        >
                          Approve
                        </Button>
                        <Button
                          primary
                          content={`ID: ${detail._id}`}
                          style = {{ backgroundColor: 'red', color: 'white' }}
                          id = {detail._id}
                          onClick = {() => handleRejection(detail.history_id, item.case_id, item.investigator_id, detail._id, detail.amount, "Rejected", item.investigator.name)}
                          disabled={ detail.receipt_status === 'Approved'  || detail.receipt.length === 0 || (detail.receipt_status ==="Rejected" && detail.receipt.length === 1)}
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                     {selectedDetail.isVisible && selectedDetail.detail_Id === detail._id && (
                      <Modal
                        open={selectedDetail.isVisible}
                        onClose={() => setSelectedDetail({ ...selectedDetail, isVisible: false })}
                      >
                        <Modal.Header>Reason for Rejection</Modal.Header>
                        <Modal.Content>
                          <div>
                            <div style={{ marginBottom: '15px' }}>
                              <strong>Investigator Name:</strong> {selectedDetail.investigator_Name}
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                              <strong>Amount:</strong> {selectedDetail.amount}
                            </div>
                            <Dropdown
                              placeholder='Select a reason'
                              fluid
                              selection
                              value={rejectReason}
                              options={rejectionReasons}
                              onChange={(e, { value }) => setRejectReason(value as string)}
                            />
                          </div>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button color='red' onClick={() => setSelectedDetail({ ...selectedDetail, isVisible: false })}>
                            Cancel
                          </Button>
                          <Button
                            primary
                            onClick={() =>
                              handleRejectConfirm(
                                selectedDetail.history_id || "",
                                selectedDetail.case_Id || "",
                                selectedDetail.investigator_Id || "",
                                selectedDetail.detail_Id || "",
                                selectedDetail.amount || "",
                                selectedDetail.receipt_Status || "",
                                rejectReason,
                                detail
                              )
                            }
                          >
                            Confirm Rejection
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    )}
                  </Table.Cell>
                </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </>
      ) : (
        <Header as='h3'>No MRD available for this Investigator...</Header>
      )}
    </Segment>
      

    </>
  );
  async function effectRemoveInvestigator() {
    if (!isRemoving) return;
    const result = await caseRepo.removeAllocatedInvestigator(model.getId());
    if (result instanceof Result.Success) {
      toast.success("Successfully removed Investigator");
      if (props.removeModel) props.removeModel(model);
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }

    setIsRemoving(false);
    setIsOpenRemoveModal(false);
  }
}
const renderEvidenceList = (evidence: URLResource[]) => {
  if (evidence.length === 0) {
    console.log(renderEvidenceList, "____=___")
    return "No evidence uploaded yet";
  } else {
    return (
      <FileLabel.Group horizontal>
        {evidence.map((it) => (
          <FileLabel title={it.getFullName()} link={it.getURL()} />
        ))}
      </FileLabel.Group>

    );
  }
};

const renderReceiptList = (evidence: string | string[]) => {
  if (evidence.length == 0) {
    return "No receipt uploaded yet";
  }
  console.log(evidence);
  const evidenceArray = Array.isArray(evidence) ? evidence : [evidence];
  const receipt = evidenceArray[evidenceArray.length - 1]; 
  if (!receipt || typeof receipt !== "string") {
    console.error("Invalid or null receipt:", receipt);
    return null; 
  }
  const receiptArray = receipt.split("/");
  const receiptName = receiptArray.pop();
  console.log("name",receiptName);
  return (
    <FileLabel.Group horizontal>
      { 
       ( ()=>{
        const envurl = config.apiBaseURL.slice(0, -3);
      const url = envurl + receipt ;
      return (
        <FileLabel title={receiptName ? receiptName:''} link={url} />
      );
      })()
      }
      {/* {evidenceArray.map((evi, index) => {
        console.log(evi,"eviiiiiiiiiiiiiii")
        // const receipt = evi[evi.length-1];
        const receiptArray: string[] = evi.split("/");
        const fullName = receiptArray[receiptArray.length - 1];
       



      })} */}
    </FileLabel.Group>
  );
};


const renderSubHeader = (model: AllocatedInvestigator) => {
  if (model.getActive()) return null;

  return (
    <Header.Subheader>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ color: "green" }}>Investigation Finished</div>
        <div style={{ margin: "0 16px" }}>{model.getOutcome()}</div>
        <div>{model.getCasePortion()}</div>
      </div>
    </Header.Subheader>
  );
};
export default InvestigatorCard;