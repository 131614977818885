import React, { useContext, useState } from "react";
import {
  Grid,
  Form,
  Header,
  Segment,
  Radio,
  DropdownItemProps,
} from "semantic-ui-react";
import FilterPageContext from "./FilterPageContext";
import { toast } from "../../../common/Toast";
import constants from "../../../../common/constants";
import DateTimePicker from "../../../app/DateTimePicker";

function Filters() {
  const ctx = useContext(FilterPageContext);

  let data: { key: string; value: any }[] = [];
  if (ctx.filters.to) data.push({ key: "to", value: ctx.filters.to });
  if (ctx.filters.from) data.push({ key: "from", value: ctx.filters.from });
  if (ctx.filters.claimNumber)
    data.push({ key: "claimNumber", value: ctx.filters.claimNumber });
  if (ctx.filters.client)
    data.push({ key: "client", value: ctx.filters.client });
  if (ctx.filters.caseType)
    data.push({ key: "caseType", value: ctx.filters.caseType });

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header as="h4">Filter</Header>
      </div>
      <Grid columns={5} >
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Radio
                toggle
                label={"Dispatch Pending"}
                checked={ctx.filters.searchByDispatchPending}
                onChange={() => {
                  ctx.filters.setSearchByDispatchPending(
                    !ctx.filters.searchByDispatchPending
                  );
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Input label="From Date">
              <DateTimePicker
                placeholder="Select From Date"
                type="date"
                value={ctx.filters.from}
                onChange={ctx.filters.setFrom}
              />
            </Form.Input>
          </Grid.Column>
          <Grid.Column>
            <Form.Input label="To Date">
              <DateTimePicker
                placeholder="Select To Date"
                type="date"
                value={ctx.filters.to}
                onChange={ctx.filters.setTo}
              />
            </Form.Input>
          </Grid.Column>

          <Grid.Column>
            <Form.Dropdown
              label="Case Type"
              search
              selection
              placeholder="Choose Case Type"
              options={ctx.options.caseType}
              value={ctx.filters.caseType}
              onChange={(_, { value }) =>
                ctx.filters.setCaseType(value as string)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Dropdown
              label="Client(TPA/Company)"
              search
              selection
              placeholder="Select Client"
              options={ctx.options.client}
              value={ctx.filters.client}
              onChange={(_, { value }) =>
                ctx.filters.setClient(value as string)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Claim Number"
              placeholder="Enter Claim Number"
              value={ctx.filters.claimNumber}
              onChange={(_, { value }) => ctx.filters.setClaimNumber(value)}
            // onKeyDown={(e:any)=>{if(e.keyCode==13)ctx.filters.setClaimNumber(claimNumber)}}
            />
          </Grid.Column>

          <Grid.Column >
            <Segment compact>
              <Radio
                toggle
                label={"Reinvestigation"}
                checked={ctx.filters.Reinvestigation}
                onChange={() => {
                  ctx.filters.setReinvestigation(
                    !ctx.filters.Reinvestigation
                  );
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment compact>
              <Radio
                toggle
                label={"Pushback"}
                checked={ctx.filters.Pushback}
                onChange={() => {
                  ctx.filters.setPushback(
                    !ctx.filters.Pushback
                  );
                }}
              />
            </Segment>
          </Grid.Column>

          <Grid.Column>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;
            <div className="ui buttons">
              <button
                className="ui blue button"
                onClick={ctx.filters.applyFilters}
              >
                Apply Filters
              </button>
              {/*<div className="or"></div>*/}
              <button
                className="ui button"
                onClick={ctx.filters.clearAllFilters}
              >
                Clear
              </button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default Filters;
