import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'

interface ContextType {
  options: Options
  filters: Filters
  count: Count
  navigate(state: string): void
}

interface Options {
  category: DropdownItemProps[]
  caseType: DropdownItemProps[]
  client: DropdownItemProps[]
  states: DropdownItemProps[]
  districts: DropdownItemProps[]
  user: DropdownItemProps[]
  userRoles: DropdownItemProps[]
  hospital: DropdownItemProps[]
}

interface Filters {
  AllDistrictS: any
  setDistrictOptions: any
  category: string
  setCategory(category: string): void
  caseType: string
  setCaseType(caseType: string): void
  state: string
  setState(state: string): void
  client: string
  setClient(client: string): void

  states: string
  setStates(states: string): void

  district: string
  setDistrict(district: string): void
  user: string
  setUser(user: string): void
  userRole: string
  setUserRole(userRole: string): void
  hospital: string
  setHospital(hospital: string): void
  claimNumber: string
  setClaimNumber(claimNumber: string): void
  insuredName: string
  setInsuredName(insuredName: string): void
  doctorName: string
  setDoctorName(doctorName: string): void
  search: string
  setSearch(search: string): void
  from: string
  setFrom(from: string): void
  to: string
  setTo(to: string): void
  getByClosedDate: boolean
  setGetByClosedDate(getByClosedDate: boolean): void
  getReinvestigation: boolean
  setGetReinvestigation(getReinvestigation: boolean): void
  getPushback: boolean
  setPushback(getPushback: boolean): void
  onChange: any
  setHospitalSearch(hospitalSearch: string): void
  hospitalSearch: string
}

interface Count {
  initiated: string
  generated: string
  open: string
  finished: string,
  pushback: string,
  fold: string
  checked: string
  close: string
  graded: string
  reinvestigate: string
}

const Context = React.createContext({} as ContextType)
export default Context
