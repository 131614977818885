
// export default {
//   apiBaseURLSocket: "http://192.168.0.100:3111",
//   mrdRecipt: 'http://192.168.12.70:3014/',
//   apiBaseURL: "http://192.168.0.100:3111/api" ,
// }
// export default {
//   apiBaseURLSocket: "http://stg-verifacto.amable.in/",
//   mrdRecipt: 'http://stg-verifacto.amable.in/',
//   apiBaseURL: "http://stg-verifacto.amable.in/api" ,
// }

// export default {
//   apiBaseURLSocket: "https://stg-verifacto.amable.in",
//   mrdRecipt: 'https://stg-verifacto.amable.in/',
//   apiBaseURL: "https://stg-verifacto.amable.in/api" ,
// }
export default {
  apiBaseURLSocket: "https://verifacto.amable.in",
  mrdRecipt: 'https://verifacto.amable.in/',
  apiBaseURL: "https://verifacto.amable.in/api" ,
}
