import React, { useEffect, useState } from 'react';
import './NotificationPopup.css';
import LocalStorage from '../../../lib/LocalStorage';
import config from '../../../common/repository/config';
import { toast } from '../../common/Toast';
import { useHistory } from 'react-router-dom';

interface DropdownItemProps {
  key: string;
  text: string;
  value: string;
}

interface NotificationPopupProps {
  notificationData: {
    typeId: string;
    history_id: string | null;
    metadata: {
      hospital_name: string | null;
      claim_number: string | null;
      insurer_name: string | null;
      charges: string | null;
      investigator_id: string | undefined;
      case_id: string | undefined;
    };
    message: string | null;
    title: string | null;
    type_id: string | undefined;
  };
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({ notificationData }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [showRejectionInput, setShowRejectionInput] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState<DropdownItemProps[]>([]);
  const [selectedRejectionReason, setSelectedRejectionReason] = useState<string>('');
  const [loadingRejectConfirm, setLoadingRejectConfirm] = useState(false);

  useEffect(() => {
    const fetchRejectionReasons = async () => {
      try {
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        if (!token) {
          console.error('Authentication token is missing.');
          return;
        }
        const response = await fetch(`${config.apiBaseURL}/case/listAmountRejectionReason`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
          },
        });

        if (!response.ok) throw new Error('Failed to fetch rejection reasons');
        const data = await response.json();

        if (data.success && data.data.items) {
          const reasons: DropdownItemProps[] = Object.entries(data.data.items).map(([key, value]) => ({
            key,
            text: value as string,
            value: key
          }));
          setRejectionReasons(reasons);
        }
      } catch (error) {
        console.error('Error fetching rejection reasons:', error);
      }
    };

    fetchRejectionReasons();
  }, []);
  useEffect(() => {
    setShowPopup(true);
  }, [notificationData]);

  const handleApproveOrReject = async (
    investigator_Id: string,
    case_id: string,
    type_id: string,
    status: string,
    historyId: string,
    reason?: string
  ) => {
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }
      const payload = {
        investigatorId: investigator_Id,
        caseId: case_id,
        typeId: type_id,
        historyId: historyId,
        amountStatus: status,
        rejectionReason: reason || null,
      };
      console.log("Payload being sent:", payload);
      const response = await fetch(`${config.apiBaseURL}/case/mrdAmountStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to ${status === 'Approved' ? 'approve' : 'reject'} notification`);
      }

      toast.success(`Notification ${status === 'Approved' ? 'approved' : 'rejected'} successfully`);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
      if (status === 'Approved') {
        setShowPopup(false); // Close popup on success
      } else {
        setShowPopup(false); // Close popup on rejection
      }
    } catch (error) {
      console.error(`Error ${status === 'Approved' ? 'approving' : 'rejecting'} notification:`, error);
    } finally {
      if (status === 'Approved') {
        setLoadingApprove(false);
      } else {
        setLoadingReject(false);
      }
    }
  };

  const handleApproveClick = async () => {
    setLoadingApprove(true);
    const { investigator_id, case_id } = notificationData.metadata;
    const { type_id, history_id } = notificationData;
    if (investigator_id && case_id && type_id && history_id) {
      await handleApproveOrReject(investigator_id, case_id, type_id, 'Approved', history_id);
    } else {
      console.error('Required fields are missing:', { investigator_id, case_id, type_id, history_id });
      setLoadingApprove(false);
    }
  };

  const handleDeclineClick = () => {
    setShowRejectionInput(true);
  };

  const handleRejectConfirm = async () => {
    setLoadingRejectConfirm(true);
    const { investigator_id, case_id } = notificationData.metadata;
    const { type_id, history_id } = notificationData;
    if (investigator_id && case_id && type_id && history_id && selectedRejectionReason) {
      await handleApproveOrReject(investigator_id, case_id, type_id, 'Rejected', history_id, selectedRejectionReason);
      setShowRejectionInput(false);
      setSelectedRejectionReason('');
    } else {
      console.error('Required fields or rejection reason is missing');
      setLoadingRejectConfirm(false);
    }
  };

  const handleRejectCancel = () => {
    setShowRejectionInput(false);
    setSelectedRejectionReason('');
  };

  const handleCaseView = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default behavior
    const caseId = notificationData.metadata.case_id;
    if (caseId) {
      window.open(`/case/view/${caseId}`, '_blank');
      setShowPopup(true); // Hide the popup in the current tab
    } else {
      console.error('Case ID is missing');
    }
  };

  if (!showPopup) return null;

  return (
    <div className={`notification-popup ${showPopup ? 'show' : ''}`}>
      <div className="overlay"></div>
      <div className="dialog-container">
        <div className="dialog-content">
          <button className="case-view-button" onClick={handleCaseView}>
            Case View
          </button>
          <div className="hospital-info">
            <div className="message">{notificationData.title}</div>
            <p>Hospital Name: {notificationData.metadata.hospital_name || 'Not Found'}</p>
            <p>Claim Number: {notificationData.metadata.claim_number || 'Not Found'}</p>
            <p>MRD Charges: {notificationData.metadata.charges || 'Not Found'}</p>
            <p>Insurer Name: {notificationData.metadata.insurer_name || 'Not Found'}</p>
          </div>
          {!showRejectionInput ? (
            <div className="button-container">
              <button
                className="dialog-button primary"
                onClick={handleApproveClick}
                disabled={loadingApprove || loadingReject}
              >
                {loadingApprove ? 'Approving...' : 'Approve'}
                {loadingApprove && <span className="loader"></span>}
              </button>

              <button className="dialog-button secondary" onClick={handleDeclineClick} disabled={loadingApprove || loadingReject}>
                {loadingReject ? 'Rejecting....' : 'Reject'}
              </button>
            </div>
          ) : (
            <div className="rejection-input-container">
              <select
                value={selectedRejectionReason}
                onChange={(e) => setSelectedRejectionReason(e.target.value)}
              >
                <option value="">Select a reason</option>
                {rejectionReasons.map((reason) => (
                  <option key={reason.key} value={reason.value}>
                    {reason.text}
                  </option>
                ))}
              </select>
              <div className="rejection-button-container">
                <button className="dialog-button primary" onClick={handleRejectConfirm} disabled={loadingRejectConfirm}>
                  {loadingRejectConfirm ? 'Rejecting...' : 'Confirm'}
                  {loadingRejectConfirm && <span className="loader"></span>}
                </button>
                <button className="dialog-button secondary" onClick={handleRejectCancel}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showToast && (
        <div className="toast">
          <p>Your request has been {showRejectionInput ? 'rejected' : 'approved'}!</p>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;