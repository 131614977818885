import React, { useState, useEffect } from "react";
import CompleteCase from "../../../../models/CompleteCase";
import { Table, Button, Header } from "semantic-ui-react";
import CaseType from "../../../../models/CaseType";
import FileLabel from "../../../common/FileLabel";
import Case from "../../../../models/Case";
import CaseRepository from "../../../../common/repository/CaseRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import constants from "../../../../common/constants";
import AllocatedClaimAnalyst from "../../../../models/AllocatedClaimAnalyst";
import InvestigationCard from "./actions/ActionQualityCheck/InvestigationCard";

interface CaseSummaryProps {
  model: CompleteCase;
  updateModel(c: Case): void;

}

const calculateHours = (startDate: Date, endDate: Date) => {
  // body...

  // Store minutes worked
  let minutesWorked = 0;

  // Validate input
  if (endDate < startDate) {
    return 0;
  }

  // Loop from your Start to End dates (by hour)
  let current = startDate;

  // Define work range
  let workHoursStart = 9;
  let workHoursEnd = 18;
  let includeWeekends = false;

  // Loop while currentDate is less than end Date (by minutes)
  while (current <= endDate) {
    // Is the current time within a work day (and if it
    // occurs on a weekend or not)
    if (
      current.getHours() >= workHoursStart &&
      current.getHours() < workHoursEnd &&
      (includeWeekends
        ? current.getDay() !== 0 && current.getDay() !== 6
        : true)
    ) {
      minutesWorked++;
    }

    // Increment current time
    current.setTime(current.getTime() + 1000 * 60);
  }

  // Return the number of hours
  return Math.ceil(minutesWorked / 60);
};

const dateToDaysString = (startDate: Date, endDate: Date) => {
  let hrs = calculateHours(startDate, endDate);
  let avTAT = "";
  let days = Math.floor(hrs / 9);
  let hours = hrs % 9;
  if (days > 1) avTAT = `${days} Days `;
  else if (days == 1) avTAT = `${days} Day `;
  if (hours > 0) avTAT += `${hours} Hours`;
  return avTAT;
};

let newAllocate: any
const getAllocationDate = async (case_id: string) => {
  let caseRepo = new CaseRepository();
  const result = await caseRepo.getQCData({ case_id: case_id });

  if (result instanceof Result.Success) {
    const qcData = result.data;
    newAllocate = qcData.getAllocations()
    const values = qcData
      .getAllocations()
      .map((it, i) => getMimumAllocationDate(`${i}`, it));
    let min_dt = values[0],
      min_dtObj = new Date(values[0]);
    values.forEach(function (dt, index) {
      if (new Date(dt) < min_dtObj) {
        min_dt = dt;
        min_dtObj = new Date(dt);
      }
    });
    return min_dt;
  } else {
    const message = result.message || "Something went wrong";
    // toast.error(message);
  }
};

const getMimumAllocationDate = (
  fakeId: string,
  allocatedClaimAnalyst: AllocatedClaimAnalyst
) => {
  const value = { ...InvestigationCard.initialValue };

  value.id = fakeId;
  value.claimAnalystId = allocatedClaimAnalyst.getClaimAnalyst().getId();
  const allocationDates = allocatedClaimAnalyst
    .getAllocatedInvestigators()
    .map((it) => {
      return new Date(it.getAllocatedOn());
    });
  let min_dt = allocationDates[0],
    min_dtObj = new Date(allocationDates[0]);
  allocationDates.forEach(function (dt, index) {
    if (new Date(dt) < min_dtObj) {
      min_dt = dt;
      min_dtObj = new Date(dt);
    }
  });

  return min_dt;
};

const CaseSummary = (props: CaseSummaryProps) => {
  const caseRepo = new CaseRepository();
  const { model, updateModel } = props;

  const portalRoot = document.getElementsByTagName("body")[0];
  const [hidden, setHidden] = useState(true);
  const company = model.getInsuranceCompany();
  const companyName = model.getInsuranceCompanyName();
  const tpa = model.getTPA();
  const insuredLocation = model.getInsuredLocation();
  const hospital = model.getHospital();
  let [allocationDate, setAllocationDate] = useState<Date | undefined>(
    undefined
  );
  let [flag, setFlag] = useState(0);

  if (flag == 0) {
    (async () => {
      let tmp = await getAllocationDate(model.getId());
      setAllocationDate(tmp);
    })();
    setFlag(1);
  }
  console.log(model, "clgmodle")
  return (
    <>
      {(model.getReinvestigation() || model.getPushback()) ? <Table color={"olive"} striped compact celled structured>
        <Table.Body style={{ fontWeight: 500 }}>
          <Table.Row positive>
            <Table.Cell width={3}>TPA/Company Name</Table.Cell>
            <Table.Cell>
              {tpa !== null
                ? `${tpa.getName()} (TPA)`
                : company !== null
                  ? company.getName()
                  : companyName}
            </Table.Cell>

            <Table.Cell width={3}>Claim Number</Table.Cell>
            <Table.Cell width={3}>{model.getClaimNumber()}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Case Type</Table.Cell>
            <Table.Cell>{model.getType().getTitle()}</Table.Cell>

            <Table.Cell>Claim Amount</Table.Cell>
            <Table.Cell>{model.getPolicyNumber()}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Insured Name</Table.Cell>
            <Table.Cell>{model.getInsuredName()}</Table.Cell>
            <Table.Cell>Insured Location</Table.Cell>
            <Table.Cell>
              {insuredLocation !== null
                ? `${insuredLocation.getDistrict()}, ${insuredLocation.getState()}`
                : ""}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Insured Address</Table.Cell>
            <Table.Cell colSpan={3}>{model.getInsuredAddress()}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Hospital</Table.Cell>
            <Table.Cell>{hospital?.getName()}</Table.Cell>
            <Table.Cell>Hospital Location</Table.Cell>
            <Table.Cell>
              {hospital?.getDistrict()}, {hospital?.getState()}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Policy Type</Table.Cell>
            <Table.Cell>{model.getPolicyType().getTitle()}</Table.Cell>
            <Table.Cell>Policy Inception</Table.Cell>
            <Table.Cell>{model.getPolicyInception()}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Triggers</Table.Cell>
            <Table.Cell colSpan={3}>
              {model
                .getTriggers()
                .split("\n")
                .map((it) => {
                  return <div>{it}</div>;
                })}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Documents</Table.Cell>
            <Table.Cell colSpan={3}>
              <FileLabel.Group horizontal>
                {model.getDocuments().map((it) => {
                  let removable = true;
                  if (model.getDocuments().length === 1) removable = false;
                  return (
                    <FileLabel
                      title={it.getFullName().slice(0, 30)}
                      link={it.getURL()}
                      removable={removable}
                      remove={async () => {
                        const result = await caseRepo.removeDocument({
                          id: model.getId(),
                          document_url: it.getURL(),
                        });
                        if (result instanceof Result.Success) {
                          const c = result.data;
                          updateModel(c);
                          toast.success("Document removed successfully");
                          return true;
                        } else {
                          const message =
                            result.message || "Something went wrong";
                          toast.error(message);
                          return false;
                        }
                      }}
                    />
                  );
                })}
              </FileLabel.Group>
            </Table.Cell>
          </Table.Row>

          {/*{!hidden && (*/}
          {/*    <>*/}
          <Table.Row>
            <Table.Cell>Case Category</Table.Cell>
            <Table.Cell>{model.getCategory().getTitle()}</Table.Cell>
            <Table.Cell>Inward DateTime</Table.Cell>
            <Table.Cell>{model.getInwardDateTime()}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Subject Line</Table.Cell>
            <Table.Cell>{model.getSubjectLine()}</Table.Cell>
            <Table.Cell>Insured Contact</Table.Cell>
            <Table.Cell>{model.getInsuredContact()}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>TAT</Table.Cell>
            <Table.Cell>
              {CaseType.formatTAT(model.getAvailableTAT())}
            </Table.Cell>
            {(model.getState() == constants.caseStates.close ||
              model.getState() == constants.caseStates.graded) && (
                <>
                  <Table.Cell>Closure TAT</Table.Cell>
                  <Table.Cell>
                    {dateToDaysString(
                      new Date(model.getInwardDateTime()),
                      new Date(model.getClosedOn()!)
                    )}
                  </Table.Cell>
                </>
              )}
            {model.getState() != constants.caseStates.close &&
              model.getState() != constants.caseStates.graded && (
                <>
                  <Table.Cell>TAT Remaining</Table.Cell>
                  <Table.Cell>
                    {CaseType.formatTAT(model.getRemainingTAT())}
                  </Table.Cell>
                </>
              )}
            {/*<Table.Cell>{CaseType.formatTAT(model.)}</Table.Cell>*/}
          </Table.Row>
          {(model.getState() == constants.caseStates.close ||
            model.getState() == constants.caseStates.graded) && (
              <Table.Row>
                <Table.Cell>Closure Remarks</Table.Cell>
                <Table.Cell colSpan={3}>{model.getFinalRemarks()}</Table.Cell>
              </Table.Row>
            )}
          {(model.getState() == constants.caseStates.close ||
            model.getState() == constants.caseStates.graded) && (
              <Table.Row>
                <Table.Cell>Allocation Delay</Table.Cell>
                <Table.Cell colSpan={3}>
                  {dateToDaysString(
                    new Date(model.getInwardDateTime()),
                    allocationDate!
                  )}
                </Table.Cell>
              </Table.Row>
            )}

          {/*</>*/}
          {/*)}*/}
        </Table.Body>
      </Table>
        : <Table striped compact celled structured>
          <Table.Body style={{ fontWeight: 500 }}>
            <Table.Row >
              <Table.Cell width={3}>TPA/Company Name</Table.Cell>
              <Table.Cell>
              {tpa !== null && company !== null
                  ? `${tpa.getName()} (TPA) - ${company.getName()}`
                  : tpa !== null
                    ? `${tpa.getName()} (TPA)`
                    : company !== null
                      ? company.getName()
                      : companyName}
              </Table.Cell>

              <Table.Cell width={3}>Claim Number</Table.Cell>
              <Table.Cell width={3}>{model.getClaimNumber()}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Case Type</Table.Cell>
              <Table.Cell>{model.getType().getTitle()}</Table.Cell>

              <Table.Cell>Claim Amount</Table.Cell>
              <Table.Cell>{model.getPolicyNumber()}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Insured Name</Table.Cell>
              <Table.Cell>{model.getInsuredName()}</Table.Cell>
              <Table.Cell>Insured Location</Table.Cell>
              <Table.Cell>
                {insuredLocation !== null
                  ? `${insuredLocation.getDistrict()}, ${insuredLocation.getState()}`
                  : ""}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Insured Address</Table.Cell>
              <Table.Cell colSpan={3}>{model.getInsuredAddress()}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Hospital</Table.Cell>
              <Table.Cell>{hospital?.getName()}</Table.Cell>
              <Table.Cell>Hospital Location</Table.Cell>
              <Table.Cell>
                {hospital?.getDistrict()}, {hospital?.getState()}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Policy Type</Table.Cell>
              <Table.Cell>{model.getPolicyType().getTitle()}</Table.Cell>
              <Table.Cell>Policy Inception</Table.Cell>
              <Table.Cell>{model.getPolicyInception()}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Triggers</Table.Cell>
              <Table.Cell colSpan={3}>
                {model
                  .getTriggers()
                  .split("\n")
                  .map((it) => {
                    return <div>{it}</div>;
                  })}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Documents</Table.Cell>
              <Table.Cell colSpan={3}>
                <FileLabel.Group horizontal>
                  {model.getDocuments().map((it) => {
                    let removable = true;
                    if (model.getDocuments().length === 1) removable = false;
                    return (
                      <FileLabel
                        title={it.getFullName().slice(0, 30)}
                        link={it.getURL()}
                        removable={removable}
                        remove={async () => {
                          const result = await caseRepo.removeDocument({
                            id: model.getId(),
                            document_url: it.getURL(),
                          });
                          if (result instanceof Result.Success) {
                            const c = result.data;
                            updateModel(c);
                            toast.success("Document removed successfully");
                            return true;
                          } else {
                            const message =
                              result.message || "Something went wrong";
                            toast.error(message);
                            return false;
                          }
                        }}
                      />
                    );
                  })}
                </FileLabel.Group>
              </Table.Cell>
            </Table.Row>

            {/*{!hidden && (*/}
            {/*    <>*/}
            <Table.Row>
              <Table.Cell>Case Category</Table.Cell>
              <Table.Cell>{model.getCategory().getTitle()}</Table.Cell>
              <Table.Cell>Inward DateTime</Table.Cell>
              <Table.Cell>{model.getInwardDateTime()}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Subject Line</Table.Cell>
              <Table.Cell>{model.getSubjectLine()}</Table.Cell>
              <Table.Cell>Insured Contact</Table.Cell>
              <Table.Cell>{model.getInsuredContact()}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>TAT</Table.Cell>
              <Table.Cell>
                {CaseType.formatTAT(model.getAvailableTAT())}
              </Table.Cell>
              {(model.getState() == constants.caseStates.close ||
                model.getState() == constants.caseStates.graded) && (
                  <>
                    <Table.Cell>Closure TAT</Table.Cell>
                    <Table.Cell>
                      {dateToDaysString(
                        new Date(model.getInwardDateTime()),
                        new Date(model.getClosedOn()!)
                      )}
                    </Table.Cell>
                  </>
                )}
              {model.getState() != constants.caseStates.close &&
                model.getState() != constants.caseStates.graded && (
                  <>
                    <Table.Cell>TAT Remaining</Table.Cell>
                    <Table.Cell>
                      {CaseType.formatTAT(model.getRemainingTAT())}
                    </Table.Cell>
                  </>
                )}
              {/*<Table.Cell>{CaseType.formatTAT(model.)}</Table.Cell>*/}
            </Table.Row>
            {(model.getState() == constants.caseStates.close ||
              model.getState() == constants.caseStates.graded) && (
                <Table.Row>
                  <Table.Cell>Closure Remarks</Table.Cell>
                  <Table.Cell colSpan={3}>{model.getFinalRemarks()}</Table.Cell>
                </Table.Row>
              )}
            {(model.getState() == constants.caseStates.close ||
              model.getState() == constants.caseStates.graded) && (
                <Table.Row>
                  <Table.Cell>Allocation Delay</Table.Cell>
                  <Table.Cell colSpan={3}>
                    {dateToDaysString(
                      new Date(model.getInwardDateTime()),
                      allocationDate!
                    )}
                  </Table.Cell>
                </Table.Row>
              )}

            {/*</>*/}
            {/*)}*/}
          </Table.Body>
        </Table>}

      {model.getReinvestigation() && (newAllocate?.map((item: any) => item?.allocatedInvestigators?.map((data: any) =>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Approved Expense</Table.HeaderCell>
              <Table.HeaderCell>MRD Expense</Table.HeaderCell>
              <Table.HeaderCell>Extra Expense</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          < Table.Body >
            <Table.Row>
              <Table.Cell>{data?.investigator?.name}</Table.Cell>
              <Table.Cell>{data?.approvedExpense}</Table.Cell>
              <Table.Cell>{data?.documentExpense}</Table.Cell>
              <Table.Cell>{data?.extraExpense}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )))}

      {/*<Button basic color='blue' size='small' onClick={() => setHidden(!hidden)}>{hidden ? 'Show more' : 'Show less'}</Button>*/}
    </>
  );
};

export default React.memo(CaseSummary);
