import React from "react";
import Page from "../../../layout/Page";
import Permissions from "./Permissions";
import { Dropdown, Segment, Button as SemanticButton } from "semantic-ui-react";
import { Description, SubTitle } from "../../../elements/Text";
import Button from "../../../elements/Button";
import Role from "../../../../models/Role";
import RoleRepository from "../../../../common/repository/RoleRespository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import RequirePermission from "../../../base/RequirePermission";
import permissions from "../../../../common/permissions";
import p, { PermissionType, StakeholderType } from "./permissionlist";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux";
import User from "../../../../models/User";
import constants from "../../../../common/constants";
import { RouteComponentProps } from "react-router-dom";
import config from "../../../../common/repository/config";
import LocalStorage from "../../../../lib/LocalStorage";
import Input from "../../../elements/Input";

interface State {
  //data that needs for the page's proper functioning
  pageData_loading: boolean;
  pageData_roles: Role[];

  selectedRole: Role | null;
  selectedRolePermissions: string[];
  selectedRoleBackendPermissions: string[];
  updatingPermissions: boolean;

  //state related to createRoleModal
  createRole_modal: boolean;
  createRole_loading: boolean;
  createRole_error: string | null;
  createRole_title: string;
  current_user: any;
  //state related to deleteRoleModal
  deleteRole_modal: boolean;
  deleteRole_loading: boolean;
  deleteRole_error: string | null;
  deleteRole_id: string;

  //state related to renameRoleModal
  renameRole_modal: boolean;
  renameRole_loading: boolean;
  renameRole_error: string | null;
  renameRole_title: string;
  renameRole_id: string;

  //add Stackholder

  stakeholderName: string;
  addingStakeholder: boolean;
  addStakeholderError: string | null;

}

interface Props extends RouteComponentProps {
  user: User | null;
  onLogout: any
}

class RolesPage extends React.Component<Props, State> {
  private roleRepo = new RoleRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      pageData_loading: false,
      pageData_roles: [],

      selectedRole: null,
      selectedRolePermissions: [],
      selectedRoleBackendPermissions: [],
      updatingPermissions: false,
      createRole_modal: false,
      createRole_loading: false,
      createRole_error: null,
      createRole_title: "",
      current_user: props.user?.getRole().getTitle(),

      deleteRole_modal: false,
      deleteRole_loading: false,
      deleteRole_error: null,
      deleteRole_id: "",

      renameRole_modal: false,
      renameRole_loading: false,
      renameRole_error: null,
      renameRole_title: "",
      renameRole_id: "",
      stakeholderName: "",
      addingStakeholder: false,
      addStakeholderError: null,
    };

  }

  componentDidMount() {
    this.getRoles();
  }
  private getRoles = () => {
    this.setState({ pageData_loading: true, selectedRole: null }, async () => {
      const result = await this.roleRepo.getRoles();
      if (result instanceof Result.Success) {
        const roles = result.data.items;
        this.setState({ pageData_loading: false, pageData_roles: roles });
      } else {
        this.setState({ pageData_loading: false }, () => {
          const message = result.message || "Could not load Roles";
          toast.error(message);
        });
      }
    });
  };

  private onPermissionToggle = (name: string, operation: "add" | "remove") => {
    const selectedRolePermissions = this.state.selectedRolePermissions;
    if (operation === "add") {
      selectedRolePermissions.push(name);
      this.setState({ selectedRolePermissions });
    } else if (operation === "remove") {
      const index = selectedRolePermissions.findIndex((it) => it === name);
      if (index === -1) return;
      selectedRolePermissions.splice(index, 1);
      this.setState({ selectedRolePermissions });
    }
  };

  private onBackendPermissionToggle = (
    name: string,
    operation: "add" | "remove"
  ) => {
    const selectedRoleBackendPermissions =
      this.state.selectedRoleBackendPermissions;
    if (operation === "add") {
      selectedRoleBackendPermissions.push(name);
      this.setState({ selectedRoleBackendPermissions });
    } else if (operation === "remove") {
      const index = selectedRoleBackendPermissions.findIndex(
        (it) => it === name
      );
      if (index === -1) return;
      selectedRoleBackendPermissions.splice(index, 1);
      this.setState({ selectedRoleBackendPermissions });
    }
  };

  private handleStakeholderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ stakeholderName: event.target.value });
  };

  private addStakeholder = async () => {
    const { stakeholderName } = this.state;
    if (!stakeholderName.trim()) {
      this.setState({ addStakeholderError: "Please provide a valid stakeholder name" });
      return;
    }
    

    this.setState({ addingStakeholder: true, addStakeholderError: null });
    try {
      const { user } = this.props;
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }

      const response = await fetch(`${config.apiBaseURL}/stakeHolderRole/addRole`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-access-token': token,
        },
        body: JSON.stringify({ role: stakeholderName }),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success("Stakeholder added successfully");
        this.setState({ stakeholderName: "" });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add stakeholder");
      }
    } catch (error) {
      // this.setState({ addStakeholderError: error.message || "Failed to add stakeholder" });
      // toast.error(error.message || "Failed to add stakeholder");
    } finally {
      this.setState({ addingStakeholder: false });
    }
  };



  private updatePermissions = () => {
    const current = this.state.current_user
    const selectedRole = this.state.selectedRole!;
    const newPermissions = this.state.selectedRolePermissions;
    const backendPermissions = this.state.selectedRoleBackendPermissions;

    this.setState({ updatingPermissions: true }, async () => {
      const result = await this.roleRepo.updateRole({
        id: selectedRole.getId(),
        name: selectedRole.getTitle(),
        level: selectedRole.getLevel(),
        permissions: newPermissions,
        backend_permissions: backendPermissions,
      });

      if (result instanceof Result.Success) {
        const updatedRole = result.data;
        const roles = this.state.pageData_roles;
        const index = roles.findIndex(
          (it) => it.getId() === updatedRole.getId()
        );

        if (index !== -1) roles[index] = updatedRole;
        if (updatedRole.getTitle() === current) {
          this.setState(
            { updatingPermissions: false, pageData_roles: [...roles] },
            () => {
              // return this.props.onLogout(),
                toast.success("Permissions updated successfully");
            }
          );
        } else {
          this.setState(
            { updatingPermissions: false, pageData_roles: [...roles] },
            () => {
              toast.success("Permissions updated successfully");
            }
          )
        }
      } else {
        this.setState({ updatingPermissions: false }, () => {
          const message = result.message || "Could not update permissions";
          toast.error(message);
        });
      }
    });
  };

  render(): JSX.Element {
    const roles = this.state.pageData_roles.map((role) => {
      return { key: role.getId(), value: role.getId(), text: role.getTitle() };
    });

    return (
      <Page
        title="Roles"
        description="A Role is assigned to a User. It is a collection of permissions that eventually the assigned user will have. A permission gives an access to a part or operation on the dashboard. You can update Roles permissions here."
      >
        {/* role selection */}
        <Segment>
          <Description>Select a Role from the dropdown below</Description>
          <Dropdown
            selection
            loading={this.state.pageData_loading}
            value={
              (this.state.selectedRole && this.state.selectedRole.getId()) || ""
            }
            placeholder="Select Role"
            onChange={(_, { value }) => {
              const selectedRole = this.state.pageData_roles.find(
                (it) => it.getId() === value
              );
              if (selectedRole)
                this.setState({
                  selectedRole,
                  selectedRolePermissions: selectedRole.getPermissions(),
                  selectedRoleBackendPermissions:
                    selectedRole.getBackendPermissions(),
                });
            }}
            options={roles}
          />
        </Segment>
        {/* role selection */}

        {/* permissions */}
        {this.renderPermissions(
          p.frontend,
          "Permissions",
          this.state.selectedRolePermissions,
          this.onPermissionToggle
        )}
        {/* permissions */}

        {this.props.user &&
          this.props.user.getRole().getTitle() === constants.roleTitle.ceo &&
          this.renderPermissions(
            p.backend,
            "Backend Permissions",
            this.state.selectedRoleBackendPermissions,
            this.onBackendPermissionToggle
          )}

        {this.state.selectedRole !== null && (
          <Button
            primary
            style={{ marginTop: 32, margin:10}}
            loading={this.state.updatingPermissions}
            disabled={this.state.updatingPermissions}
            onClick={this.updatePermissions}
          >
            Save Permissions
          </Button>
        )}
             {/* New segment for adding stakeholders */}
             {this.props.user && this.props.user.getRole().getTitle()===constants.roleTitle.ceo &&
          <div style={{display:"flex",justifyContent:"space-between"}}>
                <Segment style={{margin:"0px",width:"100%"}}>
                  <SubTitle>Add Stakeholder</SubTitle>
                  <Input
                    placeholder="Enter stakeholder name"
                    value={this.state.stakeholderName}
                    onChange={this.handleStakeholderChange}
                    style={{ marginRight: 8 }}
                  />
                  <SemanticButton
                    primary
                    loading={this.state.addingStakeholder}
                    onClick={this.addStakeholder}
                  >
                    Add   
                  </SemanticButton>
                  {this.state.addStakeholderError && (
                    <p style={{ color: 'red' }}>{this.state.addStakeholderError}</p>
                  )}
                </Segment>
                </div>

                }

      </Page>
    );
  }

  renderPermissions = (
    permissions: PermissionType[],
    title: string,
    selectedPermissions: string[],
    toggle: (name: string, operation: "add" | "remove") => void
  ) => {
    return this.state.selectedRole === null ? null : (
      <React.Fragment>
        <SubTitle>{title}</SubTitle>
        <Permissions
          list={permissions}
          permissions={selectedPermissions}
          onPermissionToggle={toggle}
        />
      </React.Fragment>
    );
  };

  
  };


export default (props: any) => {
  const user = useSelector((state: AppState) => state.auth.user);
  return (
    <RequirePermission permission={permissions.Manage.CRUD}>
      <RolesPage {...props} user={user} />
    </RequirePermission>
  );
};